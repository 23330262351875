<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>New Invoice Request</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-combobox
                        v-model="project"
                        :items="projects"
                        item-text="name"
                        hide-details
                        prepend-icon="mdi-account-hard-hat"
                        placeholder="Select a Project: "
                    >
                    </v-combobox>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn
                color="primary"
                class="mb-4"
                @click="openProformaForm"
                text
                :loading="loading"
            >
                PROFORMA
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                class="mb-4"
                @click="selectProject"
                rounded
                :loading="loading"
                :disabled="!project"
            >
                NEXT
            </v-btn>
        </v-card-actions>
        <!-- proforma form -->
        <v-dialog
            :retain-focus="false"
            v-model="proformaFormDialog"
            persistent
            max-width="640px"
        >
            <ProformaFeaturesForm
                v-if="proformaFormDialog"
                :project="project"
                :projects="projects"
                :createForm="true"
                @close="closeProformaForm"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'WorkOrderForm',
    props: {
        users: {
            type: Array,
            required: true,
        },
    },
    components: {
        ProformaFeaturesForm: () =>
            import('@/components/Invoicing/ProformaFeaturesForm.vue'),
    },
    data: () => ({
        loading: false,
        project: null,
        projects: [],
        proformaFormDialog: false,
    }),
    async mounted() {
        try {
            this.loading = true
            this.projects = await API.getLiteProjectsByUser()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        closeProformaForm() {
            this.proformaFormDialog = false
            this.close()
        },
        openProformaForm() {
            this.proformaFormDialog = true
        },
        async selectProject() {
            try {
                this.loading = true
                const quotes = await API.getProjectQuotes(this.project.id)
                const invoicesByProject = await API.getInvoices({
                    projectId: this.project.id,
                })
                const invoicesHistory = invoicesByProject.filter(
                    invoice => invoice.type != 'proforma'
                )
                const items = {}
                for (const index in quotes) {
                    const costs = await API.getCosts(quotes[index].id)
                    quotes[index].costs = costs.filter(c => c.type == 'cost')
                    items[quotes[index].id] = quotes[index].costs.map(cost => {
                        return {
                            costId: cost.id,
                            name: cost.reference,
                            pricePerUnit: cost.salePrice2 / cost.quantity,
                            qty: cost.quantity,
                            category: cost.category,
                        }
                    })
                }
                this.project.quotes = quotes
                const invoice = {
                    project: this.project,
                    projectId: this.project.id,
                    clientId: this.project.client,
                    amount: this.project.price,
                    currency: this.project.currency,
                    region: 'local',
                    items,
                }
                // set logs
                for (const invoiceHistory of invoicesHistory) {
                    for (const quoteId of Object.keys(invoice.items)) {
                        for (const cost of invoice.items[quoteId]) {
                            const log = invoiceHistory.items[quoteId]?.find(
                                c => c.costId == cost.costId
                            )
                            if (log && (log.billedQty || log.invoiceQuantity)) {
                                if (log.billedQty) {
                                    cost.billedQty = cost.billedQty
                                        ? cost.billedQty + log.billedQty
                                        : log.billedQty
                                }
                                if (log.invoiceQuantity) {
                                    log.invoiceQty = cost.invoiceQuantity
                                        ? cost.invoiceQuantity +
                                          log.invoiceQuantity
                                        : log.invoiceQuantity
                                }

                                log.number = invoiceHistory.number
                                log.notes = invoiceHistory.notes
                                log.status = invoiceHistory.status
                                const billedBy = this.users.find(
                                    user => user.id == invoiceHistory.billedBy
                                )
                                if (billedBy) {
                                    log.billedBy = billedBy.name
                                    log.billedOn = invoiceHistory.billedOn
                                }
                                const createdBy = this.users.find(
                                    user => user.id == invoiceHistory.createdBy
                                )
                                if (createdBy) {
                                    log.createdBy = createdBy.name
                                    log.createdOn = invoiceHistory.createdOn
                                }
                                if (cost.logs) {
                                    cost.logs.push(log)
                                } else {
                                    cost.logs = [log]
                                }
                                cost.logs.sort(
                                    (a, b) =>
                                        (a.createdOn.seconds ||
                                            a.createdOn._seconds) -
                                        (b.createdOn.seconds ||
                                            b.createdOn._seconds)
                                )
                            }
                        }
                    }
                }
                this.$emit('selectProject', this.project, invoice)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
